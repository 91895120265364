<template>
    <v-dialog
        :value="dialog"
        max-width="570"
        @input="$emit('input')"
    >
        <div class="coming_soon_dialog style_dialog">
            <h2 class="header">
                <div class="border_head">
                    <span class="big-letter">Attention</span>
                </div>
            </h2>
            <div class="cnt">
                <span>This action will move your album to draft</span>
            </div>
            <div class="wrap_actions">
                <button type="button" class="is-nope" @click="ok()" v-ripple>OK</button>
                <button type="button" class="is-nope" @click="cancel()" v-ripple>Cancel</button>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import {mapActions} from "vuex";

export default {
    name: "warningChangeAlbum",
    props: ['value', 'albumID'],
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
        ...mapActions({
            changeAlbumStatus: 'CHANGE_ALBUM_STATUS'
        }),
        ok() {
            this.$store.commit('SET_WARNING_CHANGE_STATUS', true);
            this.dialog = false;
            let params = {
                id: this.albumID,
                status: 'draft'
            }
            this.changeAlbumStatus(params)
                .then(() => this.dialog = false)
                .catch(err => console.log(`changeAlbumStatus, ${err}`))
        },
        cancel() {
            this.$store.commit('SET_WARNING_CHANGE_STATUS', false);
            this.dialog = false;
        }
    }
}
</script>

<style scoped>

</style>